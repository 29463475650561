import { PageFilter } from '@components/GenericOverview/types'

import { Quotation } from '@graphql/graphql'

import { PartRequestOverviewStoryblok } from '@graphql/storyblokcomponents'

export interface PartRequestOverviewProps {
	blok: PartRequestOverviewStoryblok
	assetId?: string
	locale: string
	statusTitle: string
}

export interface PartRequestPageFilters {
	status: PageFilter
	sort?: PageFilter
}

export type GoToDetailFnPartRequest = (n: Pick<Quotation, 'id'>) => any

export type PartRequestData = Pick<
	Quotation,
	'title' | 'requestedBy' | 'status' | 'dateFormatted' | 'id'
>

export enum PartRequestColumnKeys {
	title = 'title',
	dateFormatted = 'dateFormatted',
	requestedBy = 'requestedBy',
	status = 'status'
}

export interface PartRequestColumnResolver {
	title: PartRequestColumnKeys
	dateFormatted: PartRequestColumnKeys
	requestedBy: PartRequestColumnKeys
	status: PartRequestColumnKeys
}

export interface PartRequestFilterValueResolver {
	status: (caseModel: PartRequestData) => string
	[key: string]: (caseModel: PartRequestData) => string
}

export interface PartRequestFilterNames {
	status: string
}

export interface PartRequestSortingValueResolver {
	dateFormatted: string
	requestedBy: string
	status: string
	title: string
	[key: string]: string
}
