import React, { useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import {
	GetPartRequestOverviewQuery,
	GetPartRequestOverviewQueryVariables,
	QuotationOrder,
	useGetPartRequestOverviewLazyQuery,
	useGetServiceRequestStatusItemsQuery
} from '@graphql/graphql'

import Sidebar from '@components/Sidebar'
import StatusExplanation from '@components/UI/StatusExplanation'
import ErrorState from '@components/UI/Error'
import StatusExplanationSkeleton from '@skeletons/StatusExplanation'

import { Content } from '@components/UI/StatusExplanation/styles'
import { SearchField } from '@damen/ui'
import { PartRequestColumnKeys } from '@components/PartRequestOverview/types'
import GenericOverviewSkeleton from '@skeletons/GenericOverview'
import { useLocale } from '@hooks/useLocale'
import { getStartsFromLocale } from '@utils/getStartsFromLocale'
import GenericOverview2 from '@components/GenericOverview2'

import TwoColRow from '@components/TwoColRow'
import { SortingType } from '@components/GenericOverview/types'
import Spacer from '@components/Spacer'
import { DefaultGenericError } from '@components/GenericOverview2/components/GenericError/DefaultGenericError'
import { PartRequestOverviewProps } from './types'
import PartRequestOverviewTable from './PartRequestOverviewTable'
import PartRequestOverviewFilters, {
	PartRequestFilters
} from './PartRequestOverviewFilters'

const PartRequestOverview = ({ blok, assetId }: PartRequestOverviewProps) => {
	const { locale } = useLocale()
	const [load] = useGetPartRequestOverviewLazyQuery()
	const {
		data: serviceRequestStatuses,
		loading: caseStatusesLoading,
		error
	} = useGetServiceRequestStatusItemsQuery({
		variables: {
			language: locale,
			starts_with: getStartsFromLocale(locale)
		}
	})

	const items = serviceRequestStatuses?.ServicerequeststatusItems?.items

	const [showStatusPanel, setShowStatusPanel] = useState(false)

	const pageSize = 10

	const { query } = useRouter()
	const initialStatuses =
		typeof query.status === 'string'
			? query.status.split(',')
			: query.status

	const iconClickHandler = useCallback(() => {
		setShowStatusPanel(true)
	}, [setShowStatusPanel])

	return (
		<>
			<Sidebar
				isOpen={showStatusPanel}
				onClose={() => setShowStatusPanel(false)}
			>
				{error && !caseStatusesLoading && (
					<Content>
						<ErrorState
							content={
								blok?.errorDescription ??
								'Something went wrong while trying to retrieve case statuses information'
							}
							showBowWave
						/>
					</Content>
				)}
				{caseStatusesLoading && !error && <StatusExplanationSkeleton />}
				{!error && !caseStatusesLoading && items?.length > 0 && (
					<StatusExplanation
						items={items}
						title={blok.statusTitle ?? '[Status Explanation]'}
					/>
				)}
			</Sidebar>
			<GenericOverview2<
				GetPartRequestOverviewQueryVariables,
				GetPartRequestOverviewQuery,
				PartRequestFilters
			>
				loadData={useCallback(
					({ filters, searchQuery, orderBy, page }) =>
						load({
							variables: {
								language: locale,
								searchText: searchQuery,
								skip: (page - 1) * pageSize,
								limit: pageSize,
								assetIds: assetId ? [assetId] : undefined,
								status: filters.status,
								companyNames: filters.company,
								order: [
									orderBy?.[
										PartRequestColumnKeys.dateFormatted
									] === SortingType.asc &&
										QuotationOrder.DateAsc,
									(!orderBy ||
										orderBy?.[
											PartRequestColumnKeys.dateFormatted
										] === SortingType.desc) &&
										QuotationOrder.DateDesc,
									orderBy?.[PartRequestColumnKeys.title] ===
										SortingType.asc &&
										QuotationOrder.TitleAsc,
									orderBy?.[PartRequestColumnKeys.title] ===
										SortingType.desc &&
										QuotationOrder.TitleDesc,
									orderBy?.[
										PartRequestColumnKeys.requestedBy
									] === SortingType.asc &&
										QuotationOrder.RequestedByAsc,
									orderBy?.[
										PartRequestColumnKeys.requestedBy
									] === SortingType.desc &&
										QuotationOrder.RequestedByDesc,
									orderBy?.[PartRequestColumnKeys.status] ===
										SortingType.asc &&
										QuotationOrder.StatusAsc,
									orderBy?.[PartRequestColumnKeys.status] ===
										SortingType.desc &&
										QuotationOrder.StatusDesc
								].filter(Boolean)
							}
						}),
					[load, locale, pageSize, assetId]
				)}
				getTotalPages={(data) => data.quotations.total / pageSize}
				initialFilters={{
					status: initialStatuses
				}}
				renderHeader={({
					data,
					filters,
					onChangeFilters,
					searchQuery,
					setSearchQuery
				}) => (
					<>
						<TwoColRow
							mobileLayout="columnReverse"
							spacerBorderBottom={false}
							paddingBottom={false}
							left={
								<SearchField
									value={searchQuery}
									onChange={setSearchQuery}
									placeholder={blok.searchPlaceholder}
								/>
							}
						/>
						<Spacer paddingBottom={false}>
							<PartRequestOverviewFilters
								facets={data.quotations.facets}
								activeFilters={filters}
								statusFilterLabel={blok.filterStatus}
								companyFilterLabel={blok.filterCompany}
								onChangeFilters={onChangeFilters}
								noSearchResultsLabel={blok.searchNoResultsFound}
								filterSearchPlaceholder={blok.filterSearchLabel}
							/>
						</Spacer>
					</>
				)}
				renderTable={({ data, searchWord, orderBy, setOrderBy }) => (
					<PartRequestOverviewTable
						items={data.quotations.items}
						searchWord={searchWord}
						orderBy={orderBy}
						onOrderByChange={setOrderBy}
						descriptionColumnTitle={
							blok.tableDescription.split('/')[0]
						}
						systemColumnTitle={blok.tableDescription.split('/')[1]}
						dateColumnTitle={blok.tableDate}
						requestedByColumnTitle={blok.tableRequestedBy}
						statusColumnTitle={blok.tableStatus}
						onIconClick={iconClickHandler}
					/>
				)}
				renderError={({ type, searchQuery }) => (
					<DefaultGenericError
						type={type}
						searchQuery={searchQuery}
						translations={blok}
					/>
				)}
				skeleton={<GenericOverviewSkeleton filterAmount={3} />}
			/>
		</>
	)
}

export default PartRequestOverview
